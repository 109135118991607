import "styles/main.scss"
import "styles/tailwind.css"

declare global {
  interface Window {
    _altmetric: any
    _altmetric_embed_init: () => void
  }
}

export const onRouteUpdate: () => void = () => {
  if (window._altmetric) {
    window._altmetric_embed_init()
  }
}
