exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-overview-index-tsx": () => import("./../../../src/pages/about/overview/index.tsx" /* webpackChunkName: "component---src-pages-about-overview-index-tsx" */),
  "component---src-pages-about-people-index-tsx": () => import("./../../../src/pages/about/people/index.tsx" /* webpackChunkName: "component---src-pages-about-people-index-tsx" */),
  "component---src-pages-about-recognition-tsx": () => import("./../../../src/pages/about/recognition.tsx" /* webpackChunkName: "component---src-pages-about-recognition-tsx" */),
  "component---src-pages-advisory-index-tsx": () => import("./../../../src/pages/advisory/index.tsx" /* webpackChunkName: "component---src-pages-advisory-index-tsx" */),
  "component---src-pages-assessment-process-index-tsx": () => import("./../../../src/pages/assessment-process/index.tsx" /* webpackChunkName: "component---src-pages-assessment-process-index-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../../../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-double-materiality-assessment-index-tsx": () => import("./../../../src/pages/double-materiality-assessment/index.tsx" /* webpackChunkName: "component---src-pages-double-materiality-assessment-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-issue-snapshots-index-tsx": () => import("./../../../src/pages/issue-snapshots/index.tsx" /* webpackChunkName: "component---src-pages-issue-snapshots-index-tsx" */),
  "component---src-pages-newsletters-index-tsx": () => import("./../../../src/pages/newsletters/index.tsx" /* webpackChunkName: "component---src-pages-newsletters-index-tsx" */),
  "component---src-pages-our-community-tsx": () => import("./../../../src/pages/our-community.tsx" /* webpackChunkName: "component---src-pages-our-community-tsx" */),
  "component---src-pages-participate-tsx": () => import("./../../../src/pages/participate.tsx" /* webpackChunkName: "component---src-pages-participate-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-procurement-wheel-tsx": () => import("./../../../src/pages/procurement-wheel.tsx" /* webpackChunkName: "component---src-pages-procurement-wheel-tsx" */),
  "component---src-pages-resources-tsx": () => import("./../../../src/pages/resources.tsx" /* webpackChunkName: "component---src-pages-resources-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-database-tsx": () => import("./../../../src/templates/database.tsx" /* webpackChunkName: "component---src-templates-database-tsx" */),
  "component---src-templates-peer-network-tsx": () => import("./../../../src/templates/peer-network.tsx" /* webpackChunkName: "component---src-templates-peer-network-tsx" */),
  "component---src-templates-practice-tsx": () => import("./../../../src/templates/practice.tsx" /* webpackChunkName: "component---src-templates-practice-tsx" */),
  "component---src-templates-resource-collection-tsx": () => import("./../../../src/templates/resource-collection.tsx" /* webpackChunkName: "component---src-templates-resource-collection-tsx" */),
  "component---src-templates-resource-tsx": () => import("./../../../src/templates/resource.tsx" /* webpackChunkName: "component---src-templates-resource-tsx" */),
  "component---src-templates-snapshots-issue-tsx": () => import("./../../../src/templates/snapshots-issue.tsx" /* webpackChunkName: "component---src-templates-snapshots-issue-tsx" */),
  "component---src-templates-snapshots-subissue-tsx": () => import("./../../../src/templates/snapshots-subissue.tsx" /* webpackChunkName: "component---src-templates-snapshots-subissue-tsx" */)
}

